<template lang="pug">
  .fb
    .fb__container.container

      //- Титульная линия страницы
      .fb__top-line
        .fb__title-container.fb__title-container--row
          h1.fb__title {{ title }}
          el-tooltip(
            content="Дополнительная информация"
            placement="right"
            effect="light"
          )
            button.fb__tooltip.btn.btn--tooltip
              svg(width="16" height="16")
                use(xlink:href="@/assets/images/sprite/sprite.svg#icon-info")
      
      //- Правка записей
      .fb__content(
        :class="{ 'fb__content--min-height': showPreload }"
      )
        a-preload(
          v-show="showPreload"
        )
        ModelChangesLog(
          v-for="(item, $index) in modelChange"
          :key="$index"
          :logs="item"
          :allKeys="allKeys"
        )
</template>

<script>
import ModelChangesLog from './components/model-changes-log'
import APreload from '../../../components/a-preload/a-preload'

export default {
  name: 'ModelChanges',

  components: { 
    ModelChangesLog,
    APreload
  },

  data() {
    return {
      title: 'Правка записей',
      showPreload: false
    }
  },

  computed: {
    modelChange() {
      return this.$store.getters['modelChange/modelChange']
    },
    allKeys() {
      const arr = [];
      for (const [key, value] of Object.entries(this.modelChange)) {
        for (const [key1, value1] of Object.entries(this.modelChange[key].items)) {
          for (const [key2, value2] of Object.entries(value1.change)) {
            if (arr.indexOf(`${key2}`) === -1) {
              arr.push(`${key2}`)
            }
          }
          for (const [key3, value3] of Object.entries(value1.old)) {
            if (arr.indexOf(`${key3}`) === -1) {
              arr.push(`${key3}`)
            }
          }
        }
      }

      return arr;
    },
  },

  mounted() {
    this.getModelChange()
  },

  methods: {
    async getModelChange() {
      try {
        this.showPreload = true
        await this.$store.dispatch('modelChange/getModelChange')
        this.showPreload = false
      } catch (error) {
        console.log(error);
      }
    },
  }
  
}
</script>

<style lang="scss" scoped>
.logs-name {
  cursor: pointer;
}
.logs-group {
  column-count: 2;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #7d7d7d;

  p {
    margin: 0;
    padding: 0 10px;
  }
}
</style>