<template lang="pug">
  .logs
    button.logs__name.btn(
      @click="showList = !showList"
      :class="{'logs__name--open': showList}"
      ) {{ logs.name }}
      svg(width="16" height="16")
        use(xlink:href="@/assets/images/sprite/sprite.svg#icon-arrow-down")
    el-collapse-transition
      div(v-show="showList")
        .logs__container(
          v-for="(item, $index) in logs.items"
          :key="$index"
        ) 
          .logs__title-container
            p.logs__item-name Объект: #[span {{ item.model }}]
            p.logs__item-name ID: #[span {{ item.id }}]
            p.logs__item-date Дата изменения: #[span {{ getTime(new Date( Date.parse(item.update_time) )) }}]

          ul.logs__list

            //- цикл
            li.logs__item(
              v-for="key in allKeys"
              v-if="item.change[key] || item.change[key] === null || item.change[key] === 0 || item.old[key] || item.old[key] === null || item.old[key] === 0"
            )
              .logs__item-container(v-if="item.change[key] || item.change[key] === null || item.change[key] === 0")
                span.logs__item-key.logs__item-key--change {{ key }}:
                span.logs__item-change {{ item.change[key] }} (Изменено)

              .logs__item-container(v-if="item.old[key] || item.old[key] === null || item.old[key] === 0")
                span.logs__item-key {{ key }}:
                span {{ item.old[key] }}
</template>

<script>
export default {
  name: 'ModelChanges',

  props: {
    logs: {
      type: Object,
      default: () => {}
    },
    allKeys: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      showList: false,
    }
  },

  methods: {
    addLeadingZero(num) {
      return (num < 10) ? '0' + num : num
    },

    getTime(time) {
      const Y = time.getFullYear();
      const M = this.addLeadingZero(time.getMonth() + 1);
      const D = this.addLeadingZero(time.getDate());
      const h = this.addLeadingZero(time.getHours());
      const m = this.addLeadingZero(time.getMinutes());

      return `${D}.${M}.${Y} ${h}:${m}`
    }
  }
}
</script>
